/* src/pages/ProductDetails.css */

.product-details-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-details {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.product-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.product-info {
  flex: 1.5;
  display: flex;
  flex-direction: column;
}

.product-info h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}


.product-price {
  font-size: 1.5rem;
  color: #007bff;
  font-weight: bold;
  margin-bottom: 10px;
}


.product-material {
  font-size: 1.1rem;
  color: #1b1b1b;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.product-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
}

.back-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

/* Contact Section */
.contact-section {
  max-width: 800px;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-section h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.contact-section p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.contact-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-details {
    flex-direction: column;
    align-items: center;
  }

  .product-info {
    align-items: center;
    text-align: center;
  }

  .contact-section {
    padding: 15px;
  }
}
