/* src/components/Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content h4 {
  color: #fff;
}


.footer-section {
  flex: 1 1 70px;
  margin: 10px;
}

.footer-section h2, .footer-section h3  {
  font-size: 2.2em;
  margin-bottom: 7px;
  color: #fff;
}

.footer-section p {
  margin: 5px 0;
  font-size: 0.9em;
}

.footer h4 {
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #fff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #1da1f2; /* Adjust the color for hover */
}

.footer-bottom {
  background-color: #222;
  padding: 10px 0;
  font-size: 0.8em;
}

.footer-bottom p {
  margin: 0;
}

.footer-contact p {
  text-align: center;
  color: #fff;
}