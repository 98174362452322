/* src/components/Header.css */
.header {
  position: relative;
  background-image: url('/public/header-background.webp');
  background-size: cover;
  background-position: center;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay effect */
}

.header-content {
  z-index: 2;
  padding: 20px;
}

.header h1 {
  font-size: 2.8rem;
  margin: 0;
  color: #e2dada;
}

.header p {
  font-size: 1.3rem;
  margin: 10px 0 20px;
}

.header-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.header-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background-color: #0056b3;
}

.header-button-outline {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: white;
}

.header-button-outline:hover {
  background-color: #ffffff;
  color: #007bff;
}
