/* src/pages/Mouldings.css */

.mouldings-container {
  padding: 20px;
}


.filter-section {
  text-align: center;
  margin-bottom: 40px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  /* Sticky positioning */
  position: sticky;
  top: 0;
  z-index: 10; /* Keeps it above other content */
}


.filter-section h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.filter-dropdown {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  width: 180px;
  outline: none;
  transition: border-color 0.3s ease;
}

.filter-dropdown:hover,
.filter-dropdown:focus {
  border-color: #007bff;
}

.apply-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apply-button:hover {
  background-color: #0056b3;
}


/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 8px 16px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 10px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 1.1rem;
}


/* src/pages/Mouldings.css */

/* Mouldings Grid Section */
.mouldings-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 20px;
  justify-items: center;
}

@media (max-width: 1200px) {
  .mouldings-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .mouldings-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
  }
}

@media (max-width: 576px) {
  .mouldings-grid {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }
}

.moulding-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 250px; /* Optional: Limit card width */
  text-align: center;
  transition: transform 0.3s ease;
}

.moulding-card:hover {
  transform: translateY(-5px);
}
