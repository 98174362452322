/* src/pages/Home.css */
.home-container {
  padding: 20px;
}

/* About Section */
.about-section {
  text-align: center;
  margin-bottom: 40px;
}

.about-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1.6rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Featured Mouldings Section */
.featured-section {
  text-align: center;
  margin-bottom: 40px;
}

.featured-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.moulding-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.moulding-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(192, 13, 13, 0.1);
  width: 250px;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.moulding-card:hover {
  transform: translateY(-5px);
}

.moulding-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.moulding-card h3 {
  font-size: 1.2rem;
  color: #333;
  margin: 5px 0;
}

.moulding-price {
  font-size: 1.1rem;
  color: #007bff;
  margin: 5px 0;
}

.view-details {
  display: inline-block;
  padding: 8px 12px;
  margin-top: 10px;
  font-size: 0.9rem;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-details:hover {
  background-color: #0056b3;
}

/* CTA Section */
.cta-section {
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.cta-section h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.cta-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}
